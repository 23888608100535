
import React, {useState, useEffect, useRef} from 'react';

function AIInput(props) {

    return (
        <>
            <input placeholder={props.placeholder} className="ai-form-input" type={props.type}></input>
        </>
    )
}

export default AIInput;
    ;
