import React, {useState, useEffect} from 'react';

//Framer Motion
import { motion } from 'framer-motion';

//Different Header Variations
// 1 - Regular Header - ex. Why Alumo
// 2 - Single Post Header - ex. Customer Experiences - Individual
// 3 - Header With Image and Offset Background - ex. Rent
function AIPageHeader(props) {

    //Component Lifecycle
    useEffect(()=>{
        window.addEventListener('scroll', handleScroll)
        return()=>{
            window.removeEventListener('scroll', handleScroll)
        }
    })

    //Gets Header Image Height Of Variation 3 for margin bottom, so that the image does not overlap the content below
    const [imageHeight, setImageHeight] = useState();
    const getHeaderImageHeight = (event) => {
        setImageHeight(event.target.clientHeight);
    }

    //Gets page Title For animation loop
    const animateText = (text) => {
        //Add Text to an array
        const textArray = text.split(' ');

        //Initialize New Array
        const animatedText = [];

        //Adds Spaces
        textArray.forEach((item)=>{
            animatedText.push(item)
            animatedText.push("\u00A0")
        })

        // Returns New Animated Text
        return(
            animatedText.map((word, i)=>{
                return(
                    <span key={i} className={'ai-word-container'}>
                        <motion.span style={{display: 'inline-block'}}
                            initial={{y: '60px'}}
                            animate={{y: 0}}
                            transition={{duration: 1, delay: i * .040}}>
                            {word}
                        </motion.span>
                    </span>
                )
            })
        )
    }

    // scroll Listener for scroll animations
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () =>{
        setScrollPosition(window.pageYOffset)
    }

    if (props.type === 1 || props.type === 3) {
        return (
            //Header 1 + 3
            <React.Fragment>
                <section
                    className={`ai-page-header container-fluid ${`header-type-${props.type}`} ${props.className ? props.className : ''}`}
                    style={{backgroundImage: `url(${props.backgroundImage})`}}>
                    <div className={'row ai-header-content'}>
                        <div className={'ai-page-title'}>
                            <motion.h1
                                initial={{y: 0}}
                                animate={{y: -scrollPosition / 4}}
                            >{animateText(props.pageTitle)}</motion.h1>
                        </div>
                        <div className={'ai-page-subtitle'}>
                            <motion.h2
                                initial={{y: 0}}
                                animate={{y: scrollPosition / 4}}
                            >{animateText(props.pageSubtitle)}</motion.h2>
                        </div>
                    </div>
                    {
                        props.type === 3 &&
                        <div className={'row ai-header-image'}>
                            <img src={props.headerImage} alt={'Rent To Own'} onLoad={getHeaderImageHeight}/>
                        </div>
                    }
                </section>
                {
                    props.type === 3 &&
                    <div className={'ai-divider'} style={{height: imageHeight, backgroundColor: props.dividerColor}}></div>
                }
            </React.Fragment>
        )
    } else if (props.type === 2) {
        return (
            //Header 2
            <React.Fragment>
                <section
                    className={`ai-page-header ${`header-type-${props.type}`} ${props.className ? props.className : ''}`}
                    style={{backgroundImage: `url(${props.backgroundImage})`}}>
                    <div className={'row ai-header-content'}>
                        <div className={'row ai-overflow-h'}>
                            <motion.span
                                initial={{x: 0}}
                                animate={{x: -scrollPosition / 3}}
                                className={'ai-post-type'}>{props.postType}</motion.span>
                        </div>
                        <div className={'row ai-pl-0'}>
                            <div className={'ai-page-title'}>
                                <motion.h1
                                    initial={{y: 0}}
                                    animate={{y: -scrollPosition / 4}}
                                >{animateText(props.pageTitle)}</motion.h1>
                            </div>
                        </div>
                        <div className={'row ai-pl-0'}>
                            <div className={'col-md-3 ai-location ai-project-details'}>
                                <motion.i
                                    initial={{y: 0}}
                                    animate={{y: scrollPosition / 4}}
                                    className="ri-map-pin-line"></motion.i>
                                <motion.span
                                    initial={{y: 0}}
                                    animate={{y: scrollPosition / 4}}
                                >{animateText(props.location)}</motion.span>
                            </div>
                            <div className={'col-md-3 ai-project-type ai-project-details'}>
                                <motion.i
                                    initial={{y: 0}}
                                    animate={{y: scrollPosition / 4}}
                                    className="ri-lightbulb-flash-line"></motion.i>
                                <motion.span
                                    initial={{y: 0}}
                                    animate={{y: scrollPosition / 4}}
                                >{animateText(props.projectType)}</motion.span>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }

}

export default AIPageHeader;