import React, {useState} from 'react';
import AIButton from "../../components/button/button";
import alumoBanner from '../../assets/images/alumo-banner.png';
import alumoBannerBg from '../../assets/images/alumo-banner-bg.svg';
import AIAnimatedFooterText from '../footer/AnimatedFooterText';

function AILandingHero() {  

    return (
        < div className="ai-landing-hero ai-page-header-overlay ai-full-background-img " style={{ backgroundImage:`url(${alumoBannerBg})` }}>
            <div className="container w-75 ai-container d-flex justify-content-between">
                <div className="ai-landing-hero-text w-50 mt-5">
                    <h6 className="ai-text-green">The formula to efficient 

                    </h6>

                    <h2 className="text-white ai-semi-bold ai-h2">We make solar simple</h2>
                    <AIButton url={'/'} type={'ai-saving-btn'}>
                        Start saving, today!
                    </AIButton>
                </div>
                <div className="ai-landing-hero-img ai-full-background-img w-50" style={{ backgroundImage:`url(${alumoBanner})` }}></div>
            </div>
        </ div>

    )
}

export default AILandingHero;
