import React, {useEffect, useState, createRef} from 'react';
import AICard from '../components/abstract/Card';
import AIArticleCard from '../components/abstract/ArticleCard';
import AIPageNav from '../components/abstract/PageNav';
import AIButton from '../components/button/button';
import AIInput from '../components/abstract/Input';
import AITextArea from '../components/abstract/TextArea';
import AICheckbox from '../components/abstract/Checkbox';
import alumoBanner from '../assets/images/alumo-article.jpg';
import locationPin from '../assets/images/map-pin-line.svg';
import lightBulb from '../assets/images/lightbulb-flash-line.svg';
import arrowRight from '../assets/images/arrow-right-line.svg';

import alumoBannerBg from '../assets/images/alumo-footer-banner.svg';
import alumoCaseStudy from '../assets/images/case-study.jpg';
import alumoProduct from '../assets/images/alumo-product.jpg';

import { Form, Button } from 'react-bootstrap';

import { motion } from "framer-motion";

import AICta from '../components/abstract/Cta';
import AIRangeSlider from '../components/abstract/RangeSlider';


function TestPage() {


        const [validated, setValidated] = useState(false);
        const [value, setValue] = useState(1000);

        const handleSubmit = (event) => {
          const form = event.currentTarget;
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
      
          setValidated(true);
        };
           
        const logValue = value => {
            setValue(value)
        }
           
            
      
      
      return (
            <>
            <div className="container w-75 mx-auto mb-5 mt-5">

                  <h1 className="text-center"> Text Animations</h1>

  
                  <h1 className="text-center"> Cards</h1>
                  <h6 className="text-center  mt-3">No Animation</h6>
                <AICard className="mb-5" width="300px">
                        <h3 className="ai-text-blue">Rent</h3>
                        <p className="ai-text-blue">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
                </AICard>

                

                <AICard className="ai-card-animated" width="300px" bgColor="" border="2px solid #26DCFB">
                        <h3 className="ai-text-blue">Rent</h3>
                        <p className="ai-text-blue">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
                        <div>
                              <img src={arrowRight} alt="arrow right" />
                        </div>
                </AICard>

                <h6 className="text-center mt-3">Testmony Cards --- Takes Container width</h6>
                <div>
                        <AICard className="col-md-6" widht="300px" name="Name and Surname"  bgColor=""  type="testimony" >
                              <p className="" style={{fontSize: '14px', lineHeight: '25px'}}>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.”</p>
                              
                        </AICard>
                        <div className=" mt-5 d-flex justify-content-start">
                              <div >
                                    <div className=" d-flex justify-content-start align-items-center">
                                    <img alt="location" src={locationPin}/>
                                    <div className="ai-pl-1 ai-text-blue"  style={{fontSize:'12px'}}>Olifantsfontein, Gauteng</div>
                                    </div>
                              </div>
                              <div className="px-5">
                                    <div className=" d-flex justify-content-start align-items-center">
                                    <img alt="bulb" src={lightBulb}/>
                                    <div className="ai-pl-1 ai-text-blue" style={{fontSize:'12px'}}>Solar & Battery Back-Up</div>
                                    </div>
                              </div>
                        </div>
                  </div>

                  <h6 className="text-center mt-3">Smaller Testmony Cards</h6>
                  <div className="w-50 mx-auto">
                        <AICard className="col-md-6" widht="300px" bgColor=""  type="testimony" name="Name and Surnamezzzzzzzzzzzzzzz">
                              <p className="" style={{fontSize: '14px', lineHeight: '25px'}}>“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.”</p>
                              
                        </AICard>
                        <div className=" mt-5 d-flex justify-content-start">
                              <div >
                                    <div className=" d-flex justify-content-start align-items-center">
                                    <img alt="location" src={locationPin}/>
                                    <div className="ai-pl-1 ai-text-blue"  style={{fontSize:'12px'}}>Olifantsfontein, Gauteng</div>
                                    </div>
                              </div>
                              <div className="px-5">
                                    <div className=" d-flex justify-content-start align-items-center">
                                    <img alt="bulb" src={lightBulb}/>
                                    <div className="ai-pl-1 ai-text-blue" style={{fontSize:'12px'}}>Solar & Battery Back-Up</div>
                                    </div>
                              </div>
                        </div>
                  </div>
                

                <h6 className="text-center  mt-5">Article Cards</h6>
                <AICard width ="400px" className="ai-card-animated-bg " time="01 November 2021"  height="200px"  bgColor="#26DCFB"  type="article" category="Category" title="Article title goes here" bgImg={alumoBanner}>
                      <div className="ai-article-bg-img ai-full-background-img" style={{
                            border: "1px solid red",
                            backgroundImage:`url(${alumoBanner})`,
                            height: '300px'
                        }}>

                      </div>
                </AICard>


                <h6 className="text-center  mt-5">Case Study</h6>
                <AIArticleCard width ="400px" className="ai-card-animated-bg " time="01 November 2021"  height="200px" productName="Solar Battery Back-Up" location="Olifantsfontein, Gauteng"  type="product" category="Residential" title="Article title goes here" bgImg={alumoCaseStudy}>
                </AIArticleCard>

                <h6 className="text-center  mt-5">Product Card 1</h6>
                <AIArticleCard width ="400px" borderBottom="0" border="3px solid #26DCFB" bottomColor="#fff" className="ai-card-animated-bg ai-product-card" time="01 November 2021"  height="200px" productName="M4856-S Battery 2.9kWH"  location="Olifantsfontein, Gauteng"  type="product1" category="Brand Name" title="Alpha-ESS Battery" bgImg={alumoProduct}>
                </AIArticleCard>

                <h6 className="text-center  mt-5">Product Card 2</h6>
                <AIArticleCard width ="400px" borderBottom="0" border="3px solid #26DCFB" bottomColor="#fff" className="ai-card-animated-bg ai-product-card" time="01 November 2021"  height="200px" productName="M4856-S Battery 2.9kWH"  location="Olifantsfontein, Gauteng"  type="product1" category="" title="Alpha-ESS Battery" bgImg={alumoProduct}>
                </AIArticleCard>

                {/* <h6 className="text-center  mt-5">Product Card 2</h6>
                <AICard className="ai-card-animated" width="400px" bgColor="" border="2px solid #26DCFB">
                        <div>
                              <img src={alumoBanner} style={{width: '300px', margin:'0 auto'}} alt="alumo product" />
                        </div>
                        <h4 className="ai-text-blue">Alpha-ESS Battery</h4>
                        <p className="ai-text-blue">M4856-S Battery 2.9kWH</p>
                        
                </AICard> */}


                <h1 className="text-center mt-5"> FORM</h1>

                <h6 className="text-center  mt-5">Input</h6>
                <div className="text-center">
                <AIInput type= "text" placeholder="First Name"/>

                <h6 className="text-center  mt-5">Textearea</h6>
                <AITextArea rows="5" cols="50" placeholder="Additional Comments"/>


                <h6 className="text-center  mt-5">Checkbox</h6>

<AICheckbox label = "Email" />
                </div>

                <h6 className="text-center mt-5">Form Cards</h6>

                <AICard width="200px" className="ai-card-animated " >
                        <h5 className="text-center">Solar & battery</h5>
                        
                </AICard>

                <AICard width="220px" border="2px solid #26DCFB" height="60px" className=" ai-pt-1 ai-pb-1 ai-card-animated mt-2 d-flex align-items-center " >
                        <div className=" ai-semi-bold text-center">
                              Solar & battery
                        </div>
                        
                </AICard>

                <AICard width="240px" bgColor="#26DCFB" border="2px solid #26DCFB" height="130px" className=" ai-pt-1 ai-pb-1 mt-2 d-flex align-items-center " >
                        <div className=" text-center">
                              <h5 className="ai-semi-bold">Bronze</h5>
                              <p style={{fontSize:'12px'}}>Your estimated monthly bill</p>
                              <span>R1,000</span>
                        </div>
                        
                </AICard>

                {/* <AICard className="ai-card-animated" width="300px" bgColor="" border="2px solid #26DCFB">
                        <h3 className="ai-text-blue">Rent</h3>
                        <p className="ai-text-blue">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
                        <div>
                              <img src={arrowRight} alt="arrow right" />
                        </div>
                </AICard> */}

                <h6 className="text-center  mt-5">Form Error Handling</h6>
                
                <Form className="w-75 mx-auto" noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Enter email"required/>
                  <Form.Control.Feedback type="invalid">
                        Please provide a valid email.
                  </Form.Control.Feedback>
                  </Form.Group>
                  

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control type="password" placeholder="Password" />
                  </Form.Group>
                  
                        <AIButton url={'#'} type={'ai-saving-btn'} formBtn = 'yes'>
                              Start Saving
                        </AIButton>

                  
                  
                  </Form>

                <h6 className="text-center  mt-5">Slider</h6>
                <div className="w-50 mx-auto">
                  <p className="text-center">type 1</p>
                  <p className="text-center">On Change Value: {value}</p>
                  <AIRangeSlider opacity="0.2" onChangeSlider={logValue}/>
                </div>

                <div className="w-50 mx-auto">
                  <p className="text-center">type 2</p>
                  <AIRangeSlider toolBgColor="#26DCFB" toolTipTextColor="#0C233D" trackBgColor="#26DCFB" railBgColor="#EBEBEB" opacity="1" dotBgColor="#26DCFB"/>
                </div>
                <h1 className="text-center mt-5"> CTA</h1>
                <h6 className="text-center  mt-5">Default</h6>

            <AICta bgColor="#00E881" className="mb-5" width="100%" height="300px">
                <h3 className="ai-text-blue text-center">Rent</h3>
                  <p className="ai-text-blue text-center">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
                      
            </AICta>

            <h6 className="text-center  mt-5">Curved -- See bigger width at the end</h6>


            <AICta border="100%" bgColor="#00E881" className="text-center" width="100%" height="50%">
                <h3 className="ai-text-blue text-center">Rent</h3>
                  <p className="ai-text-blue text-center">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
                      
            </AICta>
            <h6 className="text-center  mt-5">Background Image</h6>

            <AICta border="45%" bgImage={alumoBannerBg} className="text-center" width="100%" height="300px">
            <h5 className="ai-text-green ai-regular">The formula to efficient energy</h5>
                <h2 className="text-white ai-semi-bold ai-h2">Get switched on today</h2>
                <AIButton url={'/'} type={'ai-saving-btn'}>
                    Start Saving
                </AIButton>
            </AICta>

            <AICta border="45%" bgImage={alumoBannerBg} className="text-center" width="100%" height="300px">
            <h5 className="ai-text-green ai-regular">The formula to efficient energy</h5>
                <h2 className="text-white ai-semi-bold ai-h2">Get switched on today</h2>
                <AIButton url={'/'} type={'ai-saving-btn'}>
                    Start Saving
                </AIButton>
            </AICta>

           
            </div>

            <div className="w-100 mx-auto mb-5">
                  <h6 className="text-center  mt-5">Page Nav</h6>
                  <div className="d-flex justify-content-center">
                        <div >
                              <AIPageNav  type="prev" subTitle="Discover more" title="House Scheepers" bgImage={alumoBannerBg}/>
                        </div>

                        <div>
                              <AIPageNav  type="next" subTitle="Discover more" title="House Scheepers" bgImage={alumoBannerBg}/>
                        </div>

                        
                  </div>
            </div>

            <h6 className="text-center  mt-5">Curved</h6>


<AICta border="100%" bgColor="#00E881" className="text-center" width="900px" height="450px">
    <h3 className="ai-text-blue text-center">Rent</h3>
      <p className="ai-text-blue text-center">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
          
</AICta>
<h6 className="text-center  mt-5">Background Image</h6>

<AICta border="100%" bgImage={alumoBannerBg} className="text-center d-flex justify-content-center align-items-center" width="100vw" height="50vw">
                <div>
                <h5 className="ai-text-green ai-regular">The formula to efficient energy</h5>
    <h2 className="text-white ai-semi-bold ai-h2">Get switched on today</h2>
    <AIButton url={'/'} type={'ai-saving-btn'}>
        Start Saving
    </AIButton>
                </div>
</AICta>

<AICta border="45%" bgImage={alumoBannerBg} className="text-center" width="100%" height="300px">
<h5 className="ai-text-green ai-regular">The formula to efficient energy</h5>
    <h2 className="text-white ai-semi-bold ai-h2">Get switched on today</h2>
    <AIButton url={'/'} type={'ai-saving-btn'}>
        Start Saving
    </AIButton>
</AICta>

            </>
      );
}

export default TestPage;
