
import React, {useState, useEffect, useRef} from 'react';

function AICheckbox(props) {

    return (
        <>
               

                <div className="ai-form-check d-flex justify-content-center align-items-center " id={props.id}>
                    <input className="ai-checkbox" id={props.id} value={props.value} name={props.name} type="checkbox"/>
                    <label className="ai-form-check-label px-2" htmlFor={props.id}> I have read and accept Alumo’s Privacy Policy</label>
                </div>
        </>
    )
}

export default AICheckbox;
    ;
