import React from 'react';

function AIRent() {
      return (
            <div className="ai-home-page">
       
            </div>
      );
}

export default AIRent;
