import React from 'react';

function AIOwn() {
      return (
            <div className="ai-home-page">
       
            </div>
      );
}

export default AIOwn;
