import React, {useState} from 'react';
import AIButton from "../../components/button/button";

function AIFooterBanner(props) {  

    return (
        <div className="ai-footer-banner ai-full-background-img-no-size d-flex align-items-center justify-content-center ai-font-regular " style={{ backgroundImage:`url(${props.bgImage})` }}>
                <div className="ai-footer-banner-text text-center  mt-5">
                    <h5 className="ai-text-green ai-regular">{props.title}</h5>
                    <h2 className="text-white ai-semi-bold ai-h2">{props.subTitle}</h2>
                    <AIButton url={'/'} type={'ai-saving-btn'}>
                        {props.buttonTitle}
                    </AIButton>
            </div>
        </div>

    )
}

export default AIFooterBanner;
