import React, {useState} from 'react';
import AIButton from "../../components/button/button";
import AICard from '../abstract/Card';
import locationPin from '../../assets/images/map-pin-line.svg';
import lightBulb from '../../assets/images/lightbulb-flash-line.svg';
import Slider from "react-slick";

function AIReviews() {  

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    return (

        < div className="ai-review-section container-fluid ai-container py-5">
            
            <h1 className="text-white text-center">Others who love Alumo</h1>
            <p className="ai-text-blue mx-auto py-2 w-50">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
            <div className="container">
                <Slider {...settings} className="row">
                            <div>
                                <AICard width=""className="col-md-6"  bgColor=""  type="testimony" name="Name and Surname">
                                        <p className="">“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.”</p>
                                        
                                </AICard>
                                <div className=" mt-5 d-flex justify-content-start">
                                        <div >
                                            <div className=" d-flex justify-content-start">
                                                <img alt="location" src={locationPin}/>
                                                <div className="ai-p ai-text-blue">Olifantsfontein, Gauteng</div>
                                            </div>
                                        </div>
                                        <div className="px-5">
                                           <div className=" d-flex justify-content-start">
                                            <img alt="bulb" src={lightBulb}/>
                                                <div className="ai-p ai-text-blue">Solar & Battery Back-Up</div>
                                           </div>
                                        </div>
                                </div>
                            </div>

                            <div>
                                <AICard width=""className="col-md-6"  bgColor=""  type="testimony" name="Name and Surname">
                                        <p className="">“Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.”</p>
                                        
                                </AICard>
                                <div className=" mt-5 d-flex justify-content-start">
                                        <div >
                                            <div className=" d-flex justify-content-start">
                                                <img alt="location" src={locationPin}/>
                                                <div className="ai-p ai-text-blue">Olifantsfontein, Gauteng</div>
                                            </div>
                                        </div>
                                        <div className="px-5">
                                           <div className=" d-flex justify-content-start">
                                            <img alt="bulb" src={lightBulb}/>
                                                <div className="ai-p ai-text-blue">Solar & Battery Back-Up</div>
                                           </div>
                                        </div>
                                </div>
                            </div>

                            
                    
                    </Slider>
            </div>
        </ div>

    )
}

export default AIReviews;
