import React from 'react';

function AICta(props) {
    let className = "ai-cta-section container-fluid ai-container py-5 " + props.className;
    if(props.bgImage !== undefined) {
        className += " ai-full-background-img";
    }

    return (
        <>
          < div className={className} style={{
              backgroundColor: props.bgColor? props.bgColor: "#0B223E",
              backgroundImage: props.bgImage?`url(${props.bgImage})`: "unset",
              height: props.height?props.height: "unset",
              width: props.width?props.width: "unset",
              borderTopRightRadius: props.border?props.border: "unset",
              borderTopLeftRadius: props.border?props.border: "unset",
              }}>
                  {props.children}
          </div>
            
        </>
    )
}

export default AICta;
    ;
