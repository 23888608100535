import React from 'react';
import 'rc-tooltip/assets/bootstrap.css';
import 'rc-slider/assets/index.css'
import Slider, { SliderTooltip } from 'rc-slider';

function AIRangeSlider(sliderProps) {  

    const { createSliderWithTooltip } = Slider;
    const Range = createSliderWithTooltip(Slider.Range);
    const { Handle } = Slider;

    const marks = {
        1000: {
            style: {
              fontSize: '20px',
              color: '#0C233D',
              fontWeight: '600',
              marginLeft: '21px'
            },
            label: '1000',
          },
        4000: {
          style: {
            fontSize: '20px',
            color: '#0C233D',
            fontWeight: '600',
            marginLeft: '-21px'
          },
          label: '4000',
        },
      };

      const onChangeHandler = value => {
          console.log(value)
      }

    const handle = props => {
    const { value, dragging, index,...restProps } = props;
    return (
        <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${value} `}
            visible={dragging}
            placement="top"
            key={index}
            tipProps={{visible:true}}
            overlayInnerStyle={{
                backgroundColor: sliderProps.toolBgColor?sliderProps.toolBgColor: '#0D223F',
                width: '88px',
                height: '49px',
                borderRadius: '25px',
                color: sliderProps.toolTipTextColor?sliderProps.toolTipTextColor: '#fff',
                fontSize: '20px',
                display: 'table-cell',
                verticalAlign: 'middle',
                textAlign: 'center',
                fontWeight: '600'
            }}
        >
        <Handle value={value} {...restProps} />
        </SliderTooltip>
    );
    };

    const wrapperStyle = { width: sliderProps.wrapperWidth?sliderProps.wrapperWidth: '100%', margin: 50 };

    return (
        <div style={wrapperStyle} className="ai-range-slider">
 
            <Slider 
                min={1000} 
                max={4000}  
                step={1000}
                defaultValue={1000} 
                handle={handle} 
                trackStyle ={{
                    backgroundColor: sliderProps.trackBgColor?sliderProps.trackBgCOlor: '#0D223F',
                    height: '5px',
                }}
                railStyle ={{
                    backgroundColor: sliderProps.railBgColor?sliderProps.railBgColor: '#0D223F',
                    opacity: sliderProps.opacity?sliderProps.opacity: '1',
                    borderRadius: '100px',
                    height: '5px',
                    
                }}
                handleStyle = {{
                    width: '15px',
                    height: '15px',
                    backgroundColor: sliderProps.dotBgColor?sliderProps.dotBgColor: '#0D223F',
                    border: '0',
                    boxShadow: 'unset'
                }}
                dotStyle = {{
                    border: 0,
                    borderRadius: 0,
                    backgroundColor: 'transparent',
                }}
                marks={marks}
                onChange = {sliderProps.onChangeSlider?sliderProps.onChangeSlider: onChangeHandler}
            />

        </div>
    )
}

export default AIRangeSlider;
