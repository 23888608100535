import React from 'react';
import {Link} from "react-router-dom";
import logo from '../../assets/images/alumo-logo.svg';
import AIFooterBanner from './FooterBanner';
import alumoBannerBg from '../../assets/images/alumo-footer-banner.svg';


import facebook from '../../assets/images/facebook-fill.svg';
import instagram from '../../assets/images/instagram-line.svg';
import linkedin from '../../assets/images/linkedin-fill.svg';
import whatsapp from '../../assets/images/whatsapp-line.svg';

import AIAnimatedFooterText from './AnimatedFooterText';
import AIMobileFooter from './MobileFooter';

const NavItems = [
    
    {
        name: 'Payment Plans',
        link: ''
    },
    {
        name: 'Solutions',
        link: ''
    }, 
    {
        name: 'Articles',
        link: ''
    },

    {
        name: 'Terms and Conditions',
        link: ''
    },

    {
        name: 'Privacy Policy',
        link: ''
    }    
    
]


function AIFooter() {
    return (
        
        <>
            <footer className="mb-0">

            <div className="ai-footer  ai-hide-desktop">
                <div className="ai-footer-wrapper container">
                    <div className="ai-top-footer d-flex justify-content-between">
                        <div >
                            <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                                <img src={logo} className="ai-app-logo" width="140" alt="Alumo Logo" />
                            </Link>
                        </div>
                        <div className="w-75">
                            <div className="d-flex justify-content-between ai-footer-contact">
                                <a href="tel:+27124807100" className="text-decoration-none">
                                    <div className="ai-footer-phone d-flex justify-content-between">
                                        <div className="ai-footer-icon ai-contact-icons   ai-border-green d-flex justify-content-center align-items-center">
                                        <i class="ri-phone-line"></i>
                                        </div>
                                        <div className="ai-footer-text w-75 ml-2">
                                            <h6 className="text-white ai-p">Call us</h6>
                                            <h4 className="ai-text-green">+27 12 480 7100</h4>
                                        </div>
                                    </div>
                                </a>
                                <a href="mailto:sales@alumo.co.za" className="text-decoration-none">
                                    <div className="ai-footer-email d-flex justify-content-between">
                                        <div className="ai-footer-icon ai-contact-icons  ai-border-green d-flex justify-content-center align-items-center">
                                                <i class="ri-mail-open-line"></i>
                                            </div>
                                            <div className="ai-footer-text w-75 ml-5">
                                                <h6 className="text-white ai-p">Email us</h6>
                                                <h4 className="ai-text-green">sales@alumo.co.za</h4>
                                            </div>
                                    </div>
                                </a>
                                <div className="ai-footer-social d-flex justify-content-between px-3">
                                       <a href="https://facebook.com" target="_blank">
                                        <div className="ai-social-icons  mr-3 ai-footer-icon ai-border-cyan d-flex justify-content-center align-items-center">
                                                <img src={facebook} alt="facebook"/>
                                            </div>
                                       </a>

                                       <a href="https://linkedin.com" target="_blank">
                                            <div className=" ai-social-icons  mr-3 ai-footer-icon ai-border-cyan d-flex justify-content-center align-items-center">
                                                <img src={linkedin} alt="2"/>
                                            </div>
                                       </a>

                                       <a href="https://instagram.com" target="_blank">
                                        <div className="ai-social-icons  mr-5 ai-footer-icon ai-border-cyan d-flex justify-content-center align-items-center">
                                                <img src={instagram} alt="3"/>
                                            </div>
                                       </a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ai-footer-divider"></div>
                    <div className="ai-bottom-footer">
                        <div className="d-flex flex-wrap justify-content-between text-white">
                        <p className="ai-copy-right">©Copyright Alumo Energy. All rights reserved. <br/> Website by <a href="https://aglet.co.za" target="_blank" className="text-decoration-none ai-text-green">Aglet. </a> SA COVID-19 Portal.</p>
                            <div>
                                <ul className="nav ai-snip">

                                    {NavItems.map((data)=>{
                                        return (
                                            <li key={data.name} className="nav-item">
                                                <Link to="/" data-hover={ data.name} className="ai-snip-link nav-link">
                                                    {data.name}
                                                    {/* <AIAnimatedFooterText text={data.name} type="paragraph"/> */}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                  
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                    <div className="ai-wtsp">
                            <a href="https://whatsapp.com" target="_blank"  className="ai-footer-wtsp nav-link d-flex justify-content-center align-items-center" to="/">
                                <img src={whatsapp} alt="3"/>
                            </a>
                        </div>
                </div>
            </div>
                <AIMobileFooter/>
            </footer>
            </>
        
    );
}

export default AIFooter;
