import React from 'react';
import {Link} from "react-router-dom";
import logo from '../../assets/images/alumo-logo.svg';

import facebook from '../../assets/images/facebook-fill.svg';
import instagram from '../../assets/images/instagram-line.svg';
import linkedin from '../../assets/images/linkedin-fill.svg';
import whatsapp from '../../assets/images/whatsapp-line.svg';



function AIMobileFooter() {
    return (
        
        <>

                <div className="container ai-mobile-footer ai-pt-2  d-lg-none" style={{position: 'relative'}}>
                       
                        <div className="text-center d-flex justify-content-center" >
                            <Link to="/" className="d-flex justify-content-between align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                                <img src={logo} className="ai-app-logo" width="140" alt="Alumo Logo" />
                            </Link>
                        </div>


                    <div className="ai-pt-2 ai-pb-2 w-75 mx-auto">
                            <a href="mailto:sales@alumo.co.za" className="text-decoration-none">
                                <div className="ai-footer-email ai-pb-2 d-flex justify-content-between">
                                    <div className="ai-footer-icon ai-contact-icons  ai-border-green d-flex justify-content-center align-items-center">
                                            <i class="ri-mail-open-line"></i>
                                        </div>
                                        <div className="ai-footer-text w-75 ml-5">
                                            <h6 className="text-white ai-p">Email us</h6>
                                            <h4 className="ai-text-green">sales@alumo.co.za</h4>
                                        </div>
                                </div>
                            </a>

                           <a href="tel:+27 12 480 7100" className="text-decoration-none mt-3">
                            <div className="ai-footer-phone d-flex justify-content-between">
                                    <div className="ai-footer-icon ai-contact-icons   ai-border-green d-flex justify-content-center align-items-center">
                                    <i class="ri-phone-line"></i>
                                    </div>
                                    <div className="ai-footer-text w-75 ml-2">
                                        <h6 className="text-white ai-p">Call us</h6>
                                        <h4 className="ai-text-green">+27 12 480 7100</h4>
                                    </div>
                                </div>
                           </a>

                    </div>

                    <div classname="ai-pt-3">
                        <ul className="list-unstyled text-center">
                                <li className="mb-3">
                                    <Link to="#!" className="text-white text-decoration-none">Payment Plans</Link>
                                </li>

                                <li  className="mb-3">
                                    <Link to="#!" className="text-white text-decoration-none mb-5">Solutions</Link>
                                </li>

                                <li  className="mb-3">
                                    <Link to="#!" className="text-white text-decoration-none mb-5">Articles</Link>
                                </li>

                                <li  className="mb-3">
                                    <Link to="#!" className="text-white text-decoration-none mb-5">Terms and Conditions</Link>
                                </li>

                                <li>
                                    <Link to="#!" className="text-white text-decoration-none">Privacy Policy</Link>
                                </li>
                        </ul>
                    </div>

                  

                    <div className="d-flex justify-content-between w-50 mx-auto ai-pt-2 ai-pb-2">
                        <div>
                            <a className="text-decoration-none" href="https://facebook.com">
                                <div className="ai-social-icons  mr-3 ai-footer-icon ai-border-cyan d-flex justify-content-center align-items-center">
                                    <img src={facebook} alt="facebook"/>
                                </div>
                            </a>
                        </div>

                        <div>
                            <a className="text-decoration-none" href="/">
                                <div className="ai-social-icons  mr-3 ai-footer-icon ai-border-cyan d-flex justify-content-center align-items-center">
                                    <img src={linkedin} alt="linkedin"/>
                                </div>
                            </a>
                        </div>

                        <div>
                           <a className="text-decoration-none" href="/">
                            <div className="ai-social-icons  mr-3 ai-footer-icon ai-border-cyan d-flex justify-content-center align-items-center">
                                    <img src={instagram} alt="instagram"/>
                                </div>
                           </a>
                        </div>
                    </div>

                    <div className="ai-wtsp ai-pt-1 ai-pb-1" style={{
                        position: 'fixed',
                        right: '20px',
                        bottom: '20px',
                    }}>
                        <Link  className="ai-footer-wtsp nav-link d-flex justify-content-center align-items-center" to="/">
                            <img src={whatsapp} alt="3"/>
                        </Link>
                    </div>

                    <div class="footer-copyright text-center text-white ai-pb-3">©Copyright Alumo Energy. All rights reserved. Website by  <Link to="/" className="ai-text-green text-decoration-none"> Aglet</Link>. SA COVID-19 Portal.
                        
                    </div>

                </div>

        </>
        
    );
}

export default AIMobileFooter;
