import React from 'react';
import './assets/sass/ai-main.scss';
import 'remixicon/fonts/remixicon.css'
import RouteIndex from './routes/RouteIndex';
import { Parallax} from '@react-spring/parallax';



function App() {
      return (

                  <div className="app">
                        <RouteIndex/>
                  </div>
            
      );
}

export default App;
