import React, {useState} from 'react';
import AISearchBar from './SearchBar';
import searchIcon from '../../assets/images/search.svg';
import { Modal } from 'react-bootstrap';
import {Link} from 'react-router-dom';

function AISearch() {  

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
        <Modal  dialogClassName="ai-search-bar-modal" show={show} fullscreen={true}  size="lg" backdrop="static" onHide={handleClose}>
        <Modal.Header>
            {/* <Link to="#" onClick={handleClose} className="ai-modal-close d-flex">
            <i className="ri-close-line"></i> Close
            </Link> */}
            </Modal.Header>

            <Modal.Body style={{width: "80%", paddingTop: "40px"}}>
                <AISearchBar onClick={handleClose}  suggestions={[
                    "Alligator",
                    "Bask",
                    "Crocodilian",
                    "Death Roll",
                    "Eggs",
                    "Jaws",
                    "Reptile",
                    "Solitary",
                    "Tail",
                    "Wetlands"
            ]}/>
            </Modal.Body>
           
        </Modal>
        
                <div className="nav-link">
                {/* <img alt="Search Icon" src={searchIcon} onClick={ai_openSearchBar} /> */}
                <img className="ai-search-icon" alt="Search Icon" src={searchIcon} onClick={handleShow} />

                </div>
        </>

    )
}

export default AISearch;
