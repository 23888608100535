import React, {useState, useEffect, useRef} from 'react';
import {NavDropdown} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
function AIDropDown(props) {  

    
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [width, setWidth] = useState(window.innerWidth);
    const navDropdownRef = useRef()


    const routeToPage = () =>{ 
        let path = props.href; 
        navigate(path);
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;
    console.log(isMobile);

    const onClick = (e) =>{ 
        if(isMobile) {
            setShow(!show);
            props.closeMenu();
        }
        routeToPage();
        
    }

    const onMouseEnter = () =>{
        if(!isMobile) {
            setShow(!show);
        }
    }

    const onMouseLeave = () =>{
        if(!isMobile) {
            setShow(false);
        }
    }


    return (
         <NavDropdown 
            ref= {navDropdownRef}
            id ={props.title.replace(/\s+/g, '')}
            href={props.href} 
            show={show}  
            title={props.title} 
            onMouseEnter={onMouseEnter} 
            onMouseLeave={onMouseLeave}
            onClick={onClick}

        >
            {props.items.map((item)=>{
                return (
                    <NavDropdown.Item key = {item.name} href={item.link}>{item.name}</NavDropdown.Item>
                )
            })}
        </NavDropdown>

    )
}

export default AIDropDown;
