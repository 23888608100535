import React from 'react';
import AICard from '../abstract/Card';
import AIButton from '../button/button';
import AIRangeSlider from '../abstract/RangeSlider';

function AILandingHero() {  

    return (
        <>
            <AICard height="250px" bgColor="#00E881" width="75%" className="ai-slider-bar">
                <div className=" d-flex justify-content-between align-items-center">
                    <div>
                        <h4 className="ai-semi-bold">Start saving, today!</h4>
                        <p className="ai-p">Select your current electricity bill to discover how</p>
                    </div>
                    <div>
                      <AIRangeSlider/>
                    </div>
                    <div>
                        <AIButton url={'/'} type="ai-saving-btn">
                            Go
                        </AIButton>
                    </div>
                </div>
            </AICard>      
        </>

    )
}

export default AILandingHero;
