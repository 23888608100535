import React, {useEffect, useState, useRef} from 'react';
import logo from '../../assets/images/alumo-logo.svg';
import {Navbar, Container, NavDropdown, Nav} from 'react-bootstrap';
import AIButton from "../button/button";
import AISearch from './Search';
import AIDropDown from './Dropdown';

function AINavigation() {

      const refs = useRef([]);

    const _NAVITEMS = [
        {
              name: 'Why Alumo',
              link: '/why-alumo',
              items: [
                    {
                          name: 'Benefits of going Alumo',
                          link: ''
                    },

                    {
                          name: 'About us',
                          link: ''
                    },

                    {
                          name: 'FAQs',
                          link: ''
                    },

                    {
                          name: 'Customer experiences',
                          link: '/customer-experiences'
                    }
              ]
        },

        {
              name: 'How you Pay',
              link: '/how-you-pay',
              items: [
                    {
                          name: 'Rent',
                          link: '/rent'
                    },
                    {
                          name: 'Own',
                          link: '/own'
                    },
                    {
                          name: 'Modular',
                          link: '/modular'
                    },
              ]
        },

        
        {
              name: 'What we do',
              link: '/what-we-do',
              items: [
                    {
                          name: 'How we do energy',
                          link: ''
                    },
                    {
                          name: 'Solar & battery solutions',
                          link: ''
                    },
                    {
                          name: 'Backup solutions',
                          link: ''
                    },
                    {
                          name: 'Products',
                          link: ''
                    },
                    {
                          name: 'Promotions',
                          link: ''
                    },
              ]
        },

        
        {
              name: 'Contact us',
              link: '/contact-us',
              items: [
                    {
                          name: 'Get in touch',
                          link: ''
                    },
                    {
                          name: 'Branches',
                          link: ''
                    },
                    {
                          name: 'Franchise opportunities',
                          link: ''
                    }
              ]
        }
        
  ]

    const [stickyClass, setStickyClass] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [show, setShow] = useState(false);


    useEffect(() => {
        window.addEventListener('scroll', stickNavbar);

        return () => {
        window.removeEventListener('scroll', stickNavbar);
        };
    }, []);

    const stickNavbar = () => {
        if (window !== undefined) {
        let windowHeight = window.scrollY;
        windowHeight > 0 ? setStickyClass('ai-bg-blue ai-fixed-top') : setStickyClass('');
        }
    };

    function overrideToggle() {
        console.log("TOGGLE TRIGGERED...")
        setExpanded(prevExpanded => !prevExpanded)
    }

    return (
            <header className="ai-header">
             
                <Navbar className={stickyClass} expand="lg" fixed="top" inverse collapseOnSelect expanded={expanded} // only need to add this when configuring the nav to detect its expanded state
            onToggle={overrideToggle}>
                <Container 
                  className = {`ai-nav-container ${expanded? "ai-expanded" : ""}`}
                >
                    <Navbar.Brand href="/">
                    <img
                        src={logo}
                        width="200"
                        className="d-inline-block align-top ai-app-logo"
                        alt="React Bootstrap logo"
                    />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    
                    <Navbar.Collapse id="basic-nav-dropdown">
                        {_NAVITEMS.map((data, index)=>{
                            return (
                                <AIDropDown closeMenu={overrideToggle}  navRef={(element) => {
                                    refs.current[index] = element;
                                  }} key ={data.name} href={data.link} title={data.name} items  = {data.items}/>
                            )     
                        })}
                        <Nav.Item>
                            <AISearch/>
                        </Nav.Item>
                        <Nav.Item>
                            <AIButton url={'/'} type={'ai-saving-btn'}>
                                Start Saving
                            </AIButton>
                        </Nav.Item>
                    </Navbar.Collapse>
                </Container>
                </Navbar>

            </header>
        
    );
}

export default  React.forwardRef((props, ref) => <AINavigation 
innerRef={ref} {...props}
/>);;
