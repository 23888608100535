
import React from 'react';

function AITextArea(props) {

    return (
        <>
            <textarea rows={props.rows}  cols={props.cols} placeholder={props.placeholder} className="ai-text-area"></textarea>
        </>
    )
}

export default AITextArea;
    ;
