import React from 'react';


//Component Imports
//Button
import AIButton from "../components/button/button";
//AIPageHeader
import AIPageHeader from '../components/page-header/page-header';
//React Tabs
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

//Framer Motion for Page transitions
import {AnimatePresence, motion} from "framer-motion";

//Assets Import
import HeaderBackground from '../assets/images/page-headers/alumo-page-header-bg-1.svg'
import HeaderBackground2 from '../assets/images/page-headers/alumo-page-header-bg-2.svg'
import HeaderImage from '../assets/images/page-headers/alumo-rent-to-own-header.png';
import CaseStudyImage from '../assets/images/page-headers/alumo-case-study-header.jpg'


function HeinPage() {

    return (
        <React.Fragment>
            <motion.div
                className={'ai-animation-overlay'}
                initial={{ position: 'fixed', backgroundColor: '#0C233D', height: '100%', width: '100%', zIndex: '9'}}
                animate={{y: '100%'}}
                transition={{duration: 0.5, delay: 0.5}}
            >
                <motion.div
                    initial={{y: '0'}}
                    animate={{y: '100vh'}}
                    transition={{duration: .5, delay: 0}}
                    className={'ai-animation-bottom'}>

                </motion.div>
            </motion.div>
        <main>
            {/*Header Variation 1*/}
            <AIPageHeader type={1} backgroundImage={HeaderBackground} pageTitle={'Why Alumo'} pageSubtitle={'We’re on a mission to make every home an Alumo home'} />

            {/*Header Variation 2*/}
            <AIPageHeader type={2} backgroundImage={CaseStudyImage} pageTitle={'House Scheepers'} postType={'Residential'} location={'Olifantsfontein, Gauteng'} projectType={'Solar & Battery Back-up'} />

            {/*Header Variation 3*/}
            <AIPageHeader type={3} backgroundImage={HeaderBackground2} pageTitle={'Rent to own'} pageSubtitle={'Pay as you go'} headerImage={HeaderImage} dividerColor={'#F2F3F5'} />

            {/* Buttons */}
            <section className={'container-fluid'}>
                <div className={'row ai-pt-clr'}>
                    <h1>Buttons</h1>
                    <div className="ai-button-row">
                        <AIButton url={'/'}>
                            Normal Button
                        </AIButton>

                        <AIButton url={'/'} type={'ai-saving-btn'}>
                            Start Saving
                        </AIButton>
                    </div>
                </div>
            </section>

            {/* TABS */}
            <section className="container-fluid ai-bg-blue">
                <div className={'container'}>
                    <div className={'row ai-pt-clr'}>
                        <h1>Tabs</h1>
                        <Tabs className={'ai-tabs'}>
                            <TabList className={'ai-tab-list'}>
                                <Tab className={'ai-tab'}>
                                    <span className={'ai-tab-heading'}>Installation</span>
                                    <span className={'ai-tab-text'}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,</span>
                                </Tab>
                                <Tab className={'ai-tab'}>
                                    <span className={'ai-tab-heading'}>Rental Contract</span>
                                    <span className={'ai-tab-text'}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,</span>
                                </Tab>
                                <Tab className={'ai-tab'}>
                                    <span className={'ai-tab-heading'}>Monthly Rental Fee</span>
                                    <span className={'ai-tab-text'}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,</span>
                                </Tab>
                                <Tab className={'ai-tab'}>
                                    <span className={'ai-tab-heading'}>Buy Out</span>
                                    <span className={'ai-tab-text'}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,</span>
                                </Tab>
                            </TabList>

                            <TabPanel className={'ai-tab-content'} >
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <h3 className={'ai-tab-content-title'}>Step 1</h3>
                                        <h4 className={'ai-tab-content-subtitle'}>Installation</h4>
                                        <p className={'ai-tab-content'}>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor.
                                        </p>
                                        <AIButton url={'/'} type={'ai-saving-btn'}>Ready to start saving?</AIButton>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <img src={CaseStudyImage} alt=""/>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel className={' ai-tab-content'}>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <h3 className={'ai-tab-content-title'}>Step 2</h3>
                                        <h4 className={'ai-tab-content-subtitle'}>Rental Contract</h4>
                                        <p className={'ai-tab-content'}>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor.
                                        </p>
                                        <AIButton url={'/'} type={'ai-saving-btn'}>Ready to start saving?</AIButton>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <img src={CaseStudyImage} alt=""/>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel className={'ai-tab-content'}>
                                <div className={'row'}>
                                    <div className={'col-md-6'}>
                                        <h3 className={'ai-tab-content-title'}>Step 3</h3>
                                        <h4 className={'ai-tab-content-subtitle'}>Monthly Rental Fee</h4>
                                        <p className={'ai-tab-content'}>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor.
                                        </p>
                                        <AIButton url={'/'} type={'ai-saving-btn'}>Ready to start saving?</AIButton>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <img src={CaseStudyImage} alt=""/>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel className={'ai-tab-content'}>
                                <div className={'row '}>
                                    <div className={'col-md-6'}>
                                        <h3 className={'ai-tab-content-title'}>Step 4</h3>
                                        <h4 className={'ai-tab-content-subtitle'}>Buy Out</h4>
                                        <p className={'ai-tab-content'}>
                                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor.
                                        </p>
                                        <AIButton url={'/'} type={'ai-saving-btn'}>Ready to start saving?</AIButton>
                                    </div>
                                    <div className={'col-md-6'}>
                                        <img src={CaseStudyImage} alt=""/>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </section>
        </main>

        </React.Fragment>
    )
}

export default HeinPage;