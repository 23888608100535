import React, {useState} from 'react';
import AIButton from "../../components/button/button";
import AICard from '../abstract/Card';
import alumoBanner from '../../assets/images/alumo-article.jpg';

function AIArticles() {  

    return (
        < div className="ai-articles-section container ai-container">
            <h1 className="ai-text-green text-center">Latest articles</h1>
            <div className="row">
                <div className="col">
                <AICard className="ai-card-animated-bg" time="01 November 2021" height="200px"  bgColor="#26DCFB"  type="article" category="Category" title="Article title goes here" bgImg={alumoBanner}>
                      <div className="ai-article-bg-img ai-full-background-img" style={{
                            border: "1px solid red",
                            backgroundImage:`url(${alumoBanner})`,
                            height: '300px'
                        }}>

                      </div>
                </AICard>
                </div>

                <div className="col">
                <AICard className="ai-card-animated-bg" time="01 November 2021"  height="200px"  bgColor="#26DCFB"  type="article" category="Category" title="Article title goes here" bgImg={alumoBanner}>
                      <div className="ai-article-bg-img ai-full-background-img" style={{
                            border: "1px solid red",
                            backgroundImage:`url(${alumoBanner})`,
                            height: '300px'
                        }}>

                      </div>
                        <h3 className="ai-text-blue">Article title goes here</h3>
                        <p className="ai-text-blue">Article title goes here</p>
                </AICard>
                </div>

                <div className="col">
                <AICard className="ai-card-animated-bg" time="01 November 2021"  height="200px"  bgColor="#26DCFB" type="article" category="Category" title="Article title goes here" bgImg={alumoBanner}>
                      <div className="ai-article-bg-img ai-full-background-img" style={{
                            border: "1px solid red",
                            backgroundImage:`url(${alumoBanner})`,
                            height: '300px'
                        }}>

                      </div>
                        <h3 className="ai-text-blue">Article title goes here</h3>
                        <p className="ai-text-blue">Article title goes here</p>
                </AICard>
                </div>
            </div>
            <div className="text-center">
                <AIButton  url={'/'}>
                View all articles
                </AIButton>
            </div>
        </ div>

    )
}

export default AIArticles;
