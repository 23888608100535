import React, {useState} from 'react';
import AIButton from "../../components/button/button";
import AICard from "../abstract/Card";
import arrowRight from '../../assets/images/arrow-right-line.svg';

function AISwitchedSection() {  

    return (
        < div className="ai-page-switched  container ai-container">
                <h1 className="ai-text-green text-center">Get switched on today</h1>
                <div className="w-50 mx-auto ai-pt-2 ai-pb-2">
                    <p className="ai-text-blue text-center">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                </div>
                <div className="d-flex justify-content-between">
                    <AICard className="ai-card-animated" width="300px" bgColor="" border="2px solid #26DCFB">
                            <h3 className="ai-text-blue">Rent</h3>
                            <p className="ai-text-blue">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
                            <div>
                                <img src={arrowRight} alt="arrow right" />
                            </div>
                    </AICard>

                    <AICard className="ai-card-animated" width="300px" bgColor="" border="2px solid #26DCFB">
                            <h3 className="ai-text-blue">Own</h3>
                            <p className="ai-text-blue">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
                            <div>
                                <img src={arrowRight} alt="arrow right" />
                            </div>
                    </AICard>

                    <AICard className="ai-card-animated" width="300px" bgColor="" border="2px solid #26DCFB">
                            <h3 className="ai-text-blue">Modular</h3>
                            <p className="ai-text-blue">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
                            <div>
                                <img src={arrowRight} alt="arrow right" />
                            </div>
                    </AICard>
                </div>
                <div className="text-center ai-pt-4 ai-pb-4">
                    <AIButton  url={'/'}>
                    Why go Alumo?
                    </AIButton>
                </div>
        </ div>

    )
}

export default AISwitchedSection;
