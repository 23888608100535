import React, {useState, useEffect, useRef} from 'react';
import AIArticleCard from './ArticleCard';

function AICard(props) {

    const className = 'card ai-card mx-auto ' + props.className;

    return (
        <>
            {props.type === 'article'? 
            <AIArticleCard type={props.type} height={props.height} time={props.time} width={props.width} title = {props.title} bgImg = {props.bgImg} category = {props.category} >
            </AIArticleCard> : 
                <div  className={className}  style = 
                    {{
                        backgroundColor: props.bgColor ? props.bgColor : '#fff',
                        width: props.width ? props.width : '100%',
                        minHeight: props.height ? props.height : '100%',
                        border: props.border ? props.border: '0',
                    }}>
                    {props.children}
                    {
                    props.type=== 'testimony' ? 
                    <div div className="text-white semi-bold d-flex align-items-center" style={{
                              height:'60px',
                              backgroundColor:'#0B223E',
                              padding: "1.5rem 2rem",
                              position: 'absolute',
                              bottom: '-30px',
                              left: 0,
                              borderTopRightRadius: '15px',
                              borderBottomRightRadius: '15px',
                              fontSize: '16px',
                              fontWeight: '600'
                              }}>{props.name}</div> : 
                              <></>
                    }

                </div>
            }
            
        </>
    )
}

export default AICard;
    ;
