
import React, {useState, useEffect, useRef} from 'react';
import timeLine from '../../assets/images/time-line.svg'

function AIArticleCard(props) {

    const className = 'card ai-card mx-auto ai-card-article ' + props.className;

    return (
        <div  className={className}  style = 
        {{
            backgroundColor: props.bottomColor ? props.bottomColor : 'transparent',
            border: props.border ? props.border : 'none',
            width: props.width ? props.width : 'unset',
           
        }}>
        <div className="ai-card-top"
         style={{
             width: "100%",
             backgroundColor: '#fff'

        }}>
            <div className="ai-card-img-bg ai-full-background-img" style={{
                        backgroundImage:`url(${props.bgImg})`,
                        height: props.height ? props.height : '100%',
                        borderTopRightRadius: '15px',
                        borderTopLeftRadius: '15px',
                        width: props.imageWidth ? props.imageWidth : '100%',
                        margin: "0 auto",
                        backgroundPosition: props.imageWidth ? "center 300%" : 'center center',

                    }}></div>
        </div>
            <div className="ai-card-bottom " style={{
                width: props.width,
                backgroundColor: props.bottomColor ? props.bottomColor : 'transparent',
                borderBottom: props.borderBottom === "0" ? 0 : '3px solid #26DCFB',
                borderLeft: props.borderBottom === "0" ? 0 : '3px solid #26DCFB',
                borderRight: props.borderBottom === "0" ? 0 : '3px solid #26DCFB',
                
            }}>

                {props.category !== '' && 
                                <div div className="text-white ai-card-badge d-flex align-items-center" style={{
                                        width:'150px', 
                                        height:'40px',
                                        backgroundColor:'#00DCFD',
                                        position: 'absolute',
                                        top: '-20px',
                                        left: '-3px',
                                        borderTopRightRadius: '15px',
                                        borderBottomRightRadius: '15px',
                                        fontSize: '16px',
                                        }}>
                                                <h3 style={{
                                fontSize: '14px',
                                color: '#0B223E',
                                marginTop: '0',
                                marginBottom: '0',
                                paddingLeft: '35px'
                                    
                                }}>{props.category}</h3>
                                    </div>
                }
                <h3 className="semi-bold" style={{
               fontSize: '18px',
               paddingTop: '20px'
                
            }}>{props.title}</h3>
               

                {props.type === 'article' &&
                     <div className="d-flex justify-content-start align-items-center ">
                        <div style={{marginTop: '-5px'}}>
                            <i class="ri-time-line" ></i>
                        </div>
                        <div>
                            <h6 className=" px-2">{props.time}</h6>
                        </div>
                    </div>
                }

                {props.type === 'product' &&
                    <>
                        <div className="d-flex justify-content-start align-items-center mt-2 mb-2 ">
                            <div >
                                <i class="ri-map-pin-2-line"></i>
                                </div>
                                <div style={{paddingTop: '10px'}}>
                                    <h6 className=" px-2">{props.location}</h6>
                                </div>
                        </div>

                        <div className="d-flex justify-content-start align-items-center  mt-2 mb-2 ">

                        <div >
                                <i class="ri-lightbulb-flash-line"></i>
                                </div>
                                <div style={{paddingTop: '10px'}}>
                                    <h6 className=" px-2">{props.productName}</h6>
                                </div>
                        </div>
                    </>
                }

                {props.type === 'product1'&&
                   <h6 className=" mt-2 mb-2">{props.productName}</h6>
                }

            </div>
        </div>
    )
}

export default AIArticleCard;
    ;
