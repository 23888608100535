import React, {useState} from 'react';
import AIButton from "../button/button";
import {Link} from 'react-router-dom';

function AISearchBar(props) {

    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState("");


      const onChange = (e) => {

            const userInput = e.target.value;
            const unLinked = props.suggestions.filter(
                  (suggestion) =>
                  suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
            );
            setInput(e.target.value);
            setFilteredSuggestions(unLinked);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
      };

      const onClick = (e) => {
            setFilteredSuggestions([]);
            setInput(e.target.innerText);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
      };

      const onKeyDown = (e) => {
            setFilteredSuggestions(props.suggestions);
            setShowSuggestions(true);
      };


      const SuggestionsListComponent = () => {
            return filteredSuggestions.length ? (
                  <ul className="ai-suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                        let className;
                        if (index === activeSuggestionIndex) {
                              className = "ai-suggestion-active";
                        }
                        return (
                              <li className={className} key={suggestion} onClick={onClick}>
                              {suggestion}
                              </li>
                        );
                        })}
                  </ul>
            ) : (
                  <ul className=" ai-suggestions ai-no-suggestions">
                        <li><em>No suggestions found!</em></li>
                  </ul>
            );
      };
    
      return (
            <div className=" ai-search-wrapper justify-content-between align-items-baseline">

                  <div className="ai-search-bar">
                              <div className='ai-search-bar-form'>
                                          <input
                                                type="text"
                                                onChange={onChange}
                                                onKeyDown={onKeyDown}
                                                value={input}
                                                placeholder="Enter your search here…"
                                          />
                                          <AIButton url={'/'} >
                                                Search
                                          </AIButton>
                              </div>
                              <div>
                                    {showSuggestions && input && <SuggestionsListComponent />}
                              </div>

                 </div>
                 
                  <div>
                  <Link to="#"  onClick={props.onClick}  class="ai-modal-close d-flex align-items-center justify-content-start" ><i class="ri-close-line"></i> Close</Link>

                  </div>
            </div>
    )
}

export default AISearchBar;