import React from 'react';

function AIPageNav(props) {
   
    return (
        <>
          {
            props.type === 'prev'? 
            <div
            style={{ 
                backgroundImage: props.bgImage? `url(${props.bgImage})` : "",
                width: props.width? props.width : "100%",
            }} 
            className="ai-page-nav ai-full-background-img  container d-flex justify-content-start align-items-center">
                <div className="" style={{marginLeft: "80px", marginRight: "80px"}}>
                    <div className=" ai-arrow ai-arrow-prev"></div>
                </div>

                <div>
                    <p className="ai-p ai-text-green">{props.subTitle}</p>
                    <h3 className="ai-semi-bold text-white">{props.title}</h3>
                </div>
            </div> : 
             <div
             style={{ backgroundImage: props.bgImage? `url(${props.bgImage})` : "",width: props.width? props.width : "100%",}} 
             className="ai-page-nav ai-full-background-img  container d-flex justify-content-end align-items-center">
                 <div style={{textAlign: 'right'}}>
                     <p className="ai-p ai-text-green">{props.subTitle}</p>
                     <h3 className="ai-semi-bold text-white">{props.title}</h3>
                 </div>

                 <div className="" style={{marginLeft: "80px", marginRight: "80px"}}>
                     <div className=" ai-arrow ai-arrow-next"></div>
                 </div>
 
                 
             </div> 
          }
            
        </>
    )
}

export default AIPageNav;
    ;
