import React from 'react';
import AILandingHero from '../components/pages/LandingHero';
import AISwitchedSection from '../components/pages/SwitchedSection';
import AIArticles from '../components/pages/Articles';
import AIReviews from '../components/pages/Reviews';
import AISliderBar from '../components/pages/SliderBar';
import AIFooterBanner from '../components/footer/FooterBanner';
import alumoBannerBg from '../assets/images/alumo-footer-banner.svg';


function Home() {
      return (
            <>
            <div className="ai-home-page">

            <AILandingHero />
            <AISliderBar/>
            <AISwitchedSection/>
            <AIArticles/>
            <AIReviews/>
            <AIFooterBanner title = "The formula to efficient energy"  subTitle = "Get switched on today" buttonTitle = "Start saving, today!" bgImage={alumoBannerBg}/>

            </div>
            </>
      );
}

export default Home;
