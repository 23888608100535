import React , {Fragment} from 'react';
import {
    BrowserRouter as Router,
    Routes ,
    Route
  } from "react-router-dom";

import Navigation from '../components/header/Navigation';

import Home from '../pages/Home';
import TestPage from '../pages/TestPage';
import HeinPage from "../pages/HeinPage";
import Footer from "../components/footer/Footer";



import AIWhyAlumo from "../pages/WhyAlumo";
import AIRent from "../pages/Rent";
import AIOWn from "../pages/Own";
import AICustomerExperience from "../pages/CustomerExperience";
import AISingleCustomerExperience from "../pages/SingleCustomerExperience";
import { ParallaxLayer } from '@react-spring/parallax';


function RouteIndex() {
      return (
            <div>
                  <Router>
                        <Fragment>

                              <div className="page-wrapper">
                                  {/* Page Header */}
                                  <Navigation/>
            

                                    {/* Page Content */}
                                    <div className="page-content">
                                          <Routes >
                                          <Route  path='/' element={<Home/>}></Route>
                                            <Route  path='/hein' element={<HeinPage/>}></Route>
                                            <Route  path='/test-page' element={<TestPage/>}></Route>
                                            <Route  path='/why-alumo' element={<AIWhyAlumo/>}></Route>
                                            <Route  path='/rent' element={<AIRent/>}></Route>
                                            <Route  path='/own' element={<AIOWn/>}></Route>
                                            <Route  path='/customer-experiences' element={<AICustomerExperience/>}></Route>
                                            <Route  path='/customer-experience:id' element={<AISingleCustomerExperience/>}></Route>

                                          </Routes>
                                    </div>
                                          <Footer/>

                                     {/* Page Footer */}
                              </div>    
                        </Fragment>
                  </Router>
            </div>
       );
}

export default RouteIndex;