import React from 'react';
import AIPageHeader from '../components/page-header/page-header';
import HeaderBackground from '../assets/images/page-headers/alumo-page-header-bg-1.svg'
import AIButton from "../components/button/button";

function AIWhyAlumo() {
      return (
            <main className="ai-home-page">
                  <AIPageHeader type={1} backgroundImage={HeaderBackground} pageTitle={'Why Alumo'} pageSubtitle={'We’re on a mission to make every home an Alumo home'} />
                  <div className="text-center container w-50 mx-auto ai-pt-4 ai-pb-4">
                        <h1 className="ai-semi-bold ai-text-green">Lorem ipsum dolor sit amet</h1>
                        <h6 className="ai-semi-bold ai-text-blue mt-5 mb-5">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo.</h6>
                        <AIButton url={'/'}>
                        Discover more about how we do energy
                        </AIButton>
                  </div>
            </main>
      );
}

export default AIWhyAlumo;
